@import "assets/icons/material-design-iconic-font/css/materialdesignicons.min.css";

@font-face {
  font-family: themify;
  src: url(assets/icons/themify-icons/fonts/themify.eot?-fvbane);
  src: url(assets/icons/themify-icons/fonts/themify.eot?#iefix-fvbane) format('embedded-opentype'), url(assets/icons/themify-icons/fonts/themify.woff?-fvbane) format('woff'), url(assets/icons/themify-icons/fonts/themify.ttf?-fvbane) format('truetype'), url(assets/icons/themify-icons/fonts/themify.svg?-fvbane#themify) format('svg');
  font-weight: 400;
  font-style: normal
}

[class*=" ti-"],
[class^=ti-] {
  font-family: themify;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.ti-clipboard:before {
  content: "\e6b4"
}

.ti-close:before {
  content: "\e646"
}

.ti-menu:before {
  content: "\e68e"
}

.ti-settings:before {
  content: "\e60f"
}

.ti-settings:before {
  content: "\e60f"
}

.ti-bar-chart:before {
  content: "\e6ba"
}
.ti-hand-point-right:before {
  content: "\e71b"
}

@font-face {
  font-family: FontAwesome;
  src: url(assets/icons/font-awesome/fonts/fontawesome-webfont.eot?v=4.5.0);
  src: url(assets/icons/font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=4.5.0) format('embedded-opentype'), url(assets/icons/font-awesome/fonts/fontawesome-webfont.woff2?v=4.5.0) format('woff2'), url(assets/icons/font-awesome/fonts/fontawesome-webfont.woff?v=4.5.0) format('woff'), url(assets/icons/font-awesome/fonts/fontawesome-webfont.ttf?v=4.5.0) format('truetype'), url(assets/icons/font-awesome/fonts/fontawesome-webfont.svg?v=4.5.0#fontawesomeregular) format('svg');
  font-weight: 400;
  font-style: normal
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.fa-calendar:before {
  content: "\f073"
}

.fa-group:before,
.fa-users:before {
  content: "\f0c0"
}

.fa-dollar:before,
.fa-usd:before {
  content: "\f155"
}

.fa-power-off:before {
  content: "\f011"
}

.fa-close:before,
.fa-remove:before,
.fa-times:before {
  content: "\f00d"
}

.fa-sliders:before {
  content: "\f1de"
}

.fa-product-hunt:before {
  content: "\f288"
}

.fa-building:before {
  content: "\f1ad"
}

.fa-angle-right:before {
  content: "\f105"
}
.fa-angle-left:before {
  content: "\f104"
}

.fa-angle-down:before {
  content: "\f107"
}

.fa-fw {
  width: 20px!important;
  display: inline-block!important;
  text-align: left!important
}

.fa-search:before {
  content: "\f002"
}

.fa-user:before {
  content: "\f007"
}

.fa-tasks:before {
  content: "\f0ae"
}

.fa-comments:before {
  content: "\f086"
}

.fa-bell:before {
  content: "\f0f3"
}

.fa-money:before {
  content: "\f0d6"
}

.fa-file:before {
  content: "\f15b"
}

.fa-file-excel-o:before {
  content: "\f1c3"
}

.fa-home:before {
  content: "\f015"
}

.fa-filter:before {
  content: "\f0b0"
}

.fa-plus-circle:before {
  content: "\f055"
}

.fa-plus:before {
  content: "\f067"
}

.fa-bars:before,
.fa-navicon:before,
.fa-reorder:before {
  content: "\f0c9"
}

.fa-floppy-o:before,
.fa-save:before {
  content: "\f0c7"
}

.fa-pencil:before {
  content: "\f040"
}

.fa-check:before {
  content: "\f00c"
}

.fa-edit:before,
.fa-pencil-square-o:before {
  content: "\f044"
}

.fa-bullseye:before {
  content: "\f140"
}

.fa-tree:before {
  content: "\f1bb"
}

.fa-trash:before {
  content: "\f1f8"
}

.fa-cogs:before,
.fa-gears:before {
  content: "\f085"
}

.fa-eye:before {
  content: "\f06e"
}

.fa-download:before {
  content: "\f019"
}

.fa-upload:before {
  content: "\f093"
}

.fa-arrow-up:before {
  content: "\f062"
}

.fa-arrow-down:before {
  content: "\f063"
}

.fa-ban:before {
  content: "\f05e"
}

.fa-history:before {
  content: "\f1da"
}

.fa-weixin:before {
  content: "\f1d7"
}

.fa-question-circle:before {
  content: "\f059"
}

.fa-microphone-slash:before {
  content:"\f131"
}
