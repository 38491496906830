// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cadmin-primary: mat.define-palette(mat.$indigo-palette);
$cadmin-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cadmin-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cadmin-theme: mat.define-light-theme((color: (primary: $cadmin-primary,
        accent: $cadmin-accent,
        warn: $cadmin-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cadmin-theme);

/* You can add global styles to this file, and also import other style files */

@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,900);

@import 'icons.scss';

@import "node_modules/bootstrap/scss/functions";

@import 'customize-bootstrap.scss';

@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";

@mixin form-validation-state-selector($state) {
  @if ($state =="valid" or $state =="invalid") {

    .was-validated #{if(&, "&", "")}:#{$state},
    #{if(&, "&", "")}.is-#{$state} {
      @content;
    }

    @if ($state =="invalid") {
      #{if(&, "&", "")}.ng-#{$state} {
        @content;
      }
    }
  }

  @else {
    #{if(&, "&", "")}.is-#{$state} {
      @content;
    }
  }
}

@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/accordion";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/toasts";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/offcanvas";
@import "node_modules/bootstrap/scss/placeholders";
@import "node_modules/bootstrap/scss/helpers";
@import "node_modules/bootstrap/scss/utilities/api";

// Angular Material
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Fix Bootstrap 5.2 tooltip positioning
.tooltip {
  position: absolute;
}

.tooltip-arrow {
  position: absolute;
}

.pointer {
  cursor:pointer;
}

body {
  font-size: 1rem;
  background-color: #ECECEC;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Montserrat, sans-serif;
  margin: 10px 0;
}

a {
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text-white a {
  color: inherit;
}

.form-control {
  font-size: 14px;
}

.required:after {
  color: red;
  content: " *";
}

.has-error .form-control {
  border-color: #ff7676;
}

.btn+.btn {
  margin-left: 10px;
}

.input-group span.input-group-text {
  background: #ffffff;
  cursor: pointer;
}

.tab-content .tab-pane {
  padding: 1rem;
  border-left: 1px solid $nav-tabs-border-color;
  border-right: 1px solid $nav-tabs-border-color;
  border-bottom: 1px solid $nav-tabs-border-color;
  @include border-bottom-radius($nav-tabs-border-radius);
}

.nav-tabs .nav-link {
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: 1rem;
  color: #000;
  border-color: $nav-tabs-border-color;
  background-color: rgb(248, 249, 250);
}

.nav-tabs .nav-link.active {
  color: $primary;
  font-weight: bold;
  border-top: 3px solid $secondary;
  padding-top: calc($nav-link-padding-y - 2px);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: break-spaces !important;
}

.popover {
  max-width: 100%;
  position: absolute;
}

.popover-arrow {
  position: absolute;
}

.ajax-table .dataTables_empty {
  display: none;
}

table.dataTable.table-striped>tbody>tr:nth-of-type(2n+1)>* {
  box-shadow: inset 0 0 0 9999px var(--#{$variable-prefix}table-accent-bg);
}

.dropify-wrapper .dropify-message p {
  font-size: 1rem;
}

.ck-editor__editable {
  min-height: 300px !important;
}


.table-box {
  display: table;
  width: 100%;

  .table-head {
    display: table-header-group;
    font-weight: bold;
    background-color: #EEE;

    .table-cell {
      background: $primary !important;
      font-weight: bold;
      border-bottom: 1px solid #839cb1;
      vertical-align: top;
      padding: 10px;
      color: #fff !important;
    }
  }

  .table-body {
    display: table-row-group;
    color: #797979;

    .table-cell {
      font-size: 13px;
      padding: 10px;
      border-bottom: 1px solid #dee2e6;
    }
  }

  .table-row {
    display: table-row;
  }

  .table-cell {
    display: table-cell;
    vertical-align: middle;
  }
}

.request-preview {
  th {
    font-weight: bold;
    font-size: 20px;
    padding: 5px 20px 5px 10px
  }

  td {
    font-size: 20px;
  }
}

.await-btn,
.accept-ico,
.decline-ico,
.declined-btn {
  border: 1px solid;
  padding: 3px;
  text-align: center;
  cursor: pointer;
  color: #f5a84d !important;
  font-size: 12px;
  border-radius: 5px;
  min-width: 80px;
  display: inline-block;
}

.status-btn {
  color: #ffffff;
  font-size: 12px;
  border-radius: 5px;
  min-width: 80px;
  display: inline-block;
  background: #00b070;
  text-align: center;
  width: auto;
}

.transfer-btn {
  color: #fff !important;
  background: #00a4de;
  border: 0;
}

.br-img {
  max-width: 60px;
  border: 1px solid #edf1f5;
}

.div-table {
  padding-left: 1rem;
  padding-right: 1rem;

  .row {
    border-bottom: rgb(222, 226, 230) solid 1px;
  }

  .row>div {
    text-align: center;
    ;
  }

  .row.heading {

    // background-color: $primary;
    &>div {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.bg-brand {
  background: rgb(228, 245, 237);
  min-height: 44px;
}

.bg-national {
  background: rgb(245, 244, 252);
  min-height: 44px;
  div:first-child {
    padding-left: 0.8rem;
  }
}

.bg-region {
  background: rgb(232, 250, 252);
  min-height: 44px;
  div:first-child {
    padding-left: 1.6rem;
  }
}

.bg-district {
  background: rgb(255, 244, 238);
  min-height: 44px;
  div:first-child {
    padding-left: 2.4rem;
  }
}

.bg-territory {
  background: rgb(255, 255, 255);
  min-height: 44px;
  div:first-child {
    padding-left: 3.2rem;
    h5 {
      font-size: 1rem;
    }
  }
}

/* TO REMOVE */
.tab-head {
  background: #f5f5f5 !important;
  color: #7d7d7e;
  padding: 10px 15px;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
}

.btn-primary[type=submit][disabled] {
  background-color: #797878;
}

.custorm-sortable-wrapper {
  .custorm-sortable {
    height: 100%;

    .fields-wrapper {
      height: 100%;

      .field-item:last-child,
      .placeholder-text {
        height: inherit;
      }
    }
  }
}
