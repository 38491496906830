$min-contrast-ratio: 3.1;

$primary: #252758;
$secondary: #ff5605;
// $link-color: #0d6efd;

$header-height: 100px;
$content-wrapper-padding: 1.5rem;
$content-wrapper-router-padding: 20px;
$footer-height: 45px;
$event-lefttab-width: 250px;
